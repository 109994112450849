import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import Tile from './Tile';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        tile1: imageSharp(fluid: {originalName: {eq: "Main-Industry-1.png"}}) {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        tile2: imageSharp(fluid: {originalName: {eq: "Main-Industry-2.png"}}) {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        tile3: imageSharp(fluid: {originalName: {eq: "Main-Industry-3.png"}}) {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        tile4: imageSharp(fluid: {originalName: {eq: "Main-Industry-4.png"}}) {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ tile1, tile2, tile3, tile4 }) => (
      <SectionWrapper className={styles.container}>
        <div className={styles.textContainer}>
          <Typography variant='h3'
            weight='bold'
            className={styles.titleContainer}>
            {i18n('Customers across multiple industries')}
          </Typography>
          <Typography variant='subtitle2'
            weight='light'
            className={styles.subtitleContainer}>
            {i18n('OBI+ helps all businesses ready to adopt connected car technology and services, regardless of size. Whether you are searching for innovative ways to drive customer retention and loyalty, optimize your business, or make vehicles more attractive, you get the tools to work for you.')}
          </Typography>
        </div>
        <Grid container className={styles.tileContainer}
          justifyContent='center'
          alignContent='center'
          alignItems='center'>
          <Tile background={tile1}
            text={i18n('Fleet management')}
            url={i18n('_url:fleet-management')}/>
          <Tile background={tile2}
            text={i18n('Leasing')}
            url={i18n('_url:leasing')}/>
          <Tile background={tile3}
            text={i18n('Repair shop')}
            url={i18n('_url:repair-shop')}/>
          <Tile background={tile4}
            text={i18n('Dealership')}
            url={i18n('_url:dealership')}/>
        </Grid>
      </SectionWrapper>
    )}/>
);