import React from 'react';
import Img from "gatsby-image/withIEPolyfill";
import { Grid } from '@material-ui/core';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ icon, title, text }) => (
  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
    className={styles.container}
    alignContent='center'
    alignItems='center'
    container>
    <Img imgStyle={{ objectFit: 'contain' }}
      objectFit='contain'
      className={styles.imageContainer}
      fluid={icon.fluid}
      alt={text}/>
    <div className={styles.textWrapper}>
      <Typography variant='h4' weight='bold' color='dark'
        align='left'
        className={styles.title}>
        {title}
      </Typography>
      <Typography variant='subtitle2' weight='light' color='dark'
        align='left'
        className={styles.text}>
        {text}
      </Typography>
    </div>
  </Grid>
);