import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import i18n from '../../../locale';
import { Grid } from '@material-ui/core';
import Typography from '../../../components/Typography';
import Advantage from './Advantage';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        advantage1: imageSharp(fluid: {originalName: {eq: "Advantage-1.jpg"}}) {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        advantage2: imageSharp(fluid: {originalName: {eq: "Advantage-2.jpg"}}) {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        advantage3: imageSharp(fluid: {originalName: {eq: "Advantage-3.jpg"}}) {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        advantage4: imageSharp(fluid: {originalName: {eq: "Advantage-4.jpg"}}) {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        advantage5: imageSharp(fluid: {originalName: {eq: "Advantage-5.jpg"}}) {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        advantage6: imageSharp(fluid: {originalName: {eq: "Advantage-6.jpg"}}) {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ advantage1, advantage2, advantage3, advantage4, advantage5, advantage6 }) => (
      <div className={styles.container}>
        <Typography variant='h2' className={styles.title}>
          {i18n('What are your advantages')}
        </Typography>
        <Grid container justifyContent='space-between'
          className={styles.tileContainer}>
          <Advantage icon={advantage1}
            title={i18n('Interoperability')}
            text={i18n('All applications work together seamlessly to provide a complete service offering.')}/>
          <Advantage icon={advantage2}
            title={i18n('Fast time to market')}
            text={i18n('Off-the-shelf applications ready to be used with the possibility for white labeling if required.')}/>
          <Advantage icon={advantage3}
            title={i18n('Easy deployment')}
            text={i18n('Seamless deployment and operation designed to facilitate the needs of every customer or partner.')}/>
          <Advantage icon={advantage4}
            title={i18n('Leveraging existing IT')}
            text={i18n('Elaborate APIs that make it easy to integrate data into existing systems.')}/>
          <Advantage icon={advantage5}
            title={i18n('Get started playbook')}
            text={i18n('Cooperating together with customers and partners to ensure smooth adoption.')}/>
          <Advantage icon={advantage6}
            title={i18n('Specialized expertise')}
            text={i18n('Years of experience with connected services leads to specific know-how on important topics.')}/>
        </Grid>
      </div>
    )}/>
)