import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './styles.module.sass';
import Typography from '../../../../components/Typography';

export default ({ background, text, url }) => (
  <Grid item xs={12} sm={6} md={6} lg={3} xl={3} className={styles.container}>
    <Link to={url}>
      <div className={styles.backgroundContainer}>
        <Img objectFit='cover'
          className={styles.imageContainer}
          fluid={background.fluid}/>
      </div>
      <Typography variant='subtitle2' 
        color='white'
        align="left"
        className={styles.textContainer}>
        {text}
      </Typography>
      <div className={styles.arrowContainer}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.1 24"
          height='100%'
          width="100%">
          <defs><style>{'.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:3px}'}</style></defs>
          <path className='cls-1' d="M21.1 1.1L32 12 21.1 23M0 12h32"/>
        </svg>
      </div>
    </Link>
  </Grid>
);