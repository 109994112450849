import React from "react";
import Layout from "../../components/Layout"
import SEO from "../../components/seo";
import sitemap from '../../data/sitemap';
import Product from './Product';
import Why from './Why';
import Solution from './Solution';
import Value from './Value';
import VehicleCare from './VehicleCare';
import Fleet from './Fleet';
import DriverApp from './DriverApp';
import Customers from './Customers';
import Advantages from './Advantages';
import CallToAction from '../CallToAction';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap._}
    url='_url:main'>
    <SEO {...seo} location={location}/>
    <Product/>
    <Why/>
    <Solution/>
    <Value/>
    <VehicleCare/>
    <Fleet/>
    <DriverApp/>
    <Customers/>
    <Advantages/>
    <CallToAction/>
  </Layout>
);