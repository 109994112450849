import React from "react";
import Content from '../views/Main';

export default ({location}) => (
  <Content location={location} seo={{
    title: "A suite of ready-to-use connected cars services",
    lang: 'en',
    description: "Expand your portfolio with a complete OBI+ suite designed to enable connected car services at scale.",
    meta: [{
        name: 'keywords',
        content: 'Connected car services'
      }]
  }}/>
);