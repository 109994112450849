import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import Button from '../../../components/Button';
import Tile from './Tile';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        solution1: imageSharp(fluid: {originalName: {eq: "Solution-1.jpg"}}) {
          fluid(maxWidth: 165, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        solution2: imageSharp(fluid: {originalName: {eq: "Solution-2.jpg"}}) {
          fluid(maxWidth: 165, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        solution3: imageSharp(fluid: {originalName: {eq: "Solution-3.jpg"}}) {
          fluid(maxWidth: 165, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        solution4: imageSharp(fluid: {originalName: {eq: "Solution-4.jpg"}}) {
          fluid(maxWidth: 165, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        solution5: imageSharp(fluid: {originalName: {eq: "Solution-5.jpg"}}) {
          fluid(maxWidth: 165, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ solution1, solution2, solution3, solution4, solution5 }) => (
      <SectionWrapper>
        <Grid container justifyContent='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}
            alignContent='center'
            alignItems='center'
            justifyContent='center'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('End-to-end solution')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Run your service the way you need it. From plug & play data sources to ready to use connected car services. At OBI+ we are committed to continuously adding capabilities to the OBI+ platform and connecting it to the leading technology of tomorrow.')}
            </Typography>
            <div className={clsx(
                styles.titleContainer,
                styles.buttonContainer
              )}>
              <Link to={i18n('_url:platform')} style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  {i18n('Read more')}
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={styles.tilesContainer}>
            <Tile img={solution1}
              text={i18n('Data sources')}/>
            <Tile img={solution2}
              text={i18n('Standardization')}/>
            <Tile img={solution3}
              text={i18n('Administration')}/>
            <Tile img={solution4}
              text={i18n('Suite of services')}/>
            <Tile img={solution5}
              text={i18n('Integration')}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);