import React from 'react';
import { Grid } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import WhyIcon from './WhyIcon';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        img1: imageSharp(fluid: {
          originalName: {eq: "Main-Why-1.jpg"}
        }) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img2: imageSharp(fluid: {
          originalName: {eq: "Main-Why-2.jpg"}
        }) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img3: imageSharp(fluid: {
          originalName: {eq: "Main-Why-3.jpg"}
        }) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
        img4: imageSharp(fluid: {
          originalName: {eq: "Main-Why-4.jpg"}
        }) {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ img1, img2, img3, img4 }) => (
      <SectionWrapper className={styles.container}>
        <Typography variant='h4'
          weight='bold'
          className={styles.titleContainer}>
          {i18n('All needed to launch and scale a connected car service.')}
        </Typography>
        {/* <Typography variant='subtitle2'
          weight='light'
          className={styles.subtitleContainer}>
          OBI+ is built for businesses that recognize the future of connected digital services. It is a powerful platform designed to enable connected car services at scale and open to all.
        </Typography> */}
        <Grid container className={styles.contentContainer}>
          <WhyIcon fluid={img1.fluid}
            text={i18n('Choose the service for your business')}/>
          <WhyIcon fluid={img2.fluid}
            text={i18n('Get access to your admin account')}/>
          <WhyIcon fluid={img3.fluid}
            text={i18n('Select the model that fits your customers')}/>
          <WhyIcon fluid={img4.fluid}
            text={i18n('Launch the service with support form OBI+')}/>
        </Grid>
      </SectionWrapper>
    )}/>
);